<template>
  <div class="hotDetail-container">
    <div class="toHome-container" @click="toHome">
      <v-iconFontSymbol :icon="'iconhome2'" style="font-size: 0.22rem" />
      <div class="text">去首页</div>
    </div>

    <div
      class="top-video"
      ref="qzServiceContent"
      v-if="state.loading ? false : true"
    >
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="state.playerOptions"
        @ready="playerReadied"
        @timeupdate="onPlayerTimeupdate($event)"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
      >
      </video-player>
    </div>

    <div class="middle-detail" v-if="state.loading ? false : true">
      <div class="details">
        <div class="contents-top">
          <span class="img-tips"
            ><v-iconFontSymbol
              :icon="'iconblue_study'"
              class="left-container-svg"
          /></span>
          <span class="text">{{ state.play_times }}人已学习</span>
        </div>

        <div class="share-container" @click="toCollect">
          <span class="img-tips"
            ><v-iconFontSymbol
              :icon="'iconcollect'"
              class="left-container-svg1"
          /></span>
          <span class="text">{{ !state.collect_tag ? "已收藏" : "收藏" }}</span>
        </div>
        <van-collapse v-model="activeNames" :border="false">
          <van-collapse-item name="1" :border="false">
            <template #title>
              <div class="titles">
                <span class="left-titles">{{ state.video_name }}</span>
                <span>简介</span>
              </div>
            </template>
            <div class="contents">
              <div class="contents-middle">
                {{ state.video_intro }}
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>

      <div class="list-bottom-container">
        <div class="list1" v-if="state.isHot">
          <div class="title">相关推荐</div>
          <VideoListComponent
            :activeStyle="true"
            :toDetail="reloadVideo"
            :dataList="state.videoList"
          />
        </div>
        <div class="list2" v-if="!state.isHot">
          <div class="title">课程目录</div>

          <div class="list-class-container">
            <SubjectListComponent
              :videoList="state.videoList"
              :reloadVideo="reloadVideo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  getCurrentInstance,
  onMounted,
  reactive,
  onBeforeUnmount,
} from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";

import classApi from "@/api/class";

import { friendsCircle } from "@/tools/common";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import VideoListComponent from "@/components/videos/VideoListComponent";
import SubjectListComponent from "@/components/videos/SubjectListComponent";
// const wx = require("weixin-js-sdk");

export default {
  name: "ClassHotDetail",
  components: {
    VideoListComponent,
    SubjectListComponent,
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const state = reactive({
      loading: true,
      videoList: [],
      collect_tag: true,
      video_intro: "",
      video_name: "",
      play_times: "",
      gklog: "0",
      video_id: "",
      play_progress: "",
      special_name: "",
      isHot: false,
      video_index: 0,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      },
    });
    const router = useRouter();
    const activeNames = ref(["1"]);
    const { proxy } = getCurrentInstance();

    const getListData = async () => {
      const res = await proxy.$request.handleFetchPost(classApi.video_list, {});
      Toast.clear();
      if (res.video_list) {
        state.videoList = res.video_list;
      }
    };

    const collectStatus = async (video_id) => {
      const res = await proxy.$request.handleFetchPostJson(
        classApi.col_status,
        {
          video_id: video_id,
        }
      );
      Toast.clear();
      state.collect_tag = res.collect_tag;
    };

    const toCollectTag = async (video_id, tag) => {
      await proxy.$request.handleFetchPostJson(classApi.collections, {
        video_id: video_id,
        tag: tag,
      });
      collectStatus(video_id);
    };

    const getSignature = () => {
      friendsCircle(
        state.video_name,
        state.video_intro,
        window.location.href,
        state.playerOptions.poster
      );
    };

    const playHistory = async (video_id) => {
      await proxy.$request.handleFetchPostJson(classApi.history, {
        video_id: video_id,
      });
    };

    const playTimes = async (video_id) => {
      await proxy.$request.handleFetchPostJson(classApi.times, {
        video_id: video_id,
      });
    };

    const setProgress = async (params) => {
      await proxy.$request.handleFetchPostJson(classApi.progress, params);
    };

    const getSubjectDetail = async (special_name) => {
      const res = await proxy.$request.handleFetchPostJson(
        classApi.sub_detail,
        { subject_name: special_name }
      );
      Toast.clear();
      const video_list = res.video_list || [];
      const video_name_arr = [],
        backup_video_list = [];
      video_list.forEach((item) => {
        video_name_arr.push(item.video_name);
      });
      video_name_arr.sort();
      video_name_arr.forEach((item, index) => {
        video_list.forEach((data) => {
          if (item == data.video_name) {
            backup_video_list[index] = data;
          }
        });
      });
      backup_video_list.forEach((item, index) => {
        if (index == state.video_index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });

      const first_video = backup_video_list[state.video_index];
      if (backup_video_list.length != 0) {
        state.video_name = first_video.video_name;
        state.playerOptions.sources[0].src = first_video.play_url;
        state.playerOptions.poster = first_video.surface;
        state.video_intro = first_video.video_intro;
        state.play_times = first_video.play_times;
        state.video_id = first_video.video_id;
        state.loading = false;
        state.play_progress =
          first_video.play_progress == "undefined"
            ? "0"
            : first_video.play_progress;
        state.gklog =
          first_video.play_progress == "undefined"
            ? "0"
            : first_video.play_progress;
        state.collect_tag = first_video.collect_tag;
        state.videoList = backup_video_list;
        collectStatus(first_video.video_id);
        getSignature();
        Toast.clear();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    };

    const getVideoDetail = async (video_id) => {
      const res = await proxy.$request.handleFetchPostJson(
        classApi.video_info,
        {
          video_id: video_id,
        }
      );
      state.video_name = res.video_info.video_name;
      state.surface = res.video_info.surface;
      state.playerOptions.sources[0].src = res.video_info.play_url;
      state.playerOptions.poster = res.video_info.surface;
      state.video_intro = res.video_info.video_intro;
      state.play_times = res.video_info.play_times;
      state.play_progress = res.video_info.play_progress;
      state.gklog = res.video_info.play_progress;
      state.loading = false;
      collectStatus(video_id);
      getSignature();
    };

    const reloadVideo = (video_id, isHot, video_index) => {
      if (isHot) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        state.video_id = video_id;
        getVideoDetail(video_id);
        router.replace({
          path: "/hotDetail",
          name: "hotDetail",
          query: {
            isHot: isHot,
            video_id: video_id,
          },
        });
      } else {
        state.video_index = video_index;
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        getSubjectDetail(state.special_name);
        if (!isHot) {
          state.videoList.forEach((item) => {
            if (item.video_id == video_id) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }
        router.replace({
          path: "/hotDetail",
          name: "hotDetail",
          query: {
            isHot: isHot,
            special_name: state.special_name,
            video_index: video_index,
          },
        });
      }
    };

    const toCollect = () => {
      let tag = state.collect_tag ? false : true;
      toCollectTag(state.video_id, tag);
    };

    const onPlayerTimeupdate = (player) => {
      state.gklog = player.cache_.currentTime;
    };

    const playerReadied = (player) => {
      player.currentTime(state.gklog);
    };

    const onPlayerPlay = () => {
      playHistory(state.video_id);
      playTimes(state.video_id);
    };

    const onPlayerPause = () => {
      let params = {
        video_id: state.video_id,
        play_progress: state.gklog + "",
        study_state: "学习中",
      };
      setProgress(params);
    };

    onMounted(() => {
      const { isHot, video_id, special_name, video_index } =
        router.currentRoute.value.query;
      state.isHot = isHot == "true" ? true : false;
      state.video_id = video_id;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      if (state.isHot) {
        collectStatus(video_id);
        getVideoDetail(video_id);
        getListData();
      } else {
        state.video_index = video_index;
        state.special_name = special_name;
        getSubjectDetail(special_name);
      }
    });

    onBeforeUnmount(() => {
      let params = {
        play_progress: state.gklog + "",
        video_id: state.video_id,
        study_state: state.gklog == "0" ? "未学习" : "已学习",
      };
      setProgress(params);
    });

    const toHome = () => {
      router.replace({
        name: "home",
      });
    };

    return {
      activeNames,
      state,
      playHistory,
      onPlayerTimeupdate,
      playerReadied,
      onPlayerPlay,
      onPlayerPause,
      reloadVideo,
      toCollect,
      toHome,
    };
  },
};
</script>
