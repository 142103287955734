<template>
  <div
    class="list-container"
    v-for="(item, index) in dataList"
    :key="index"
    @click="
      activeStyle
        ? toDetail(item.video_id, true)
        : toDetailOther(item.special_name)
    "
  >
    <div class="left-contents">
      <img :src="item && item.surface" />
      <div class="bottom-right-times" v-if="activeStyle">
        {{ item.video_time }}
      </div>
    </div>
    <div class="right-contents">
      <div class="title">
        {{ activeStyle ? item && item.video_name : item && item.special_name }}
      </div>
      <div class="tips" v-if="activeStyle">
        <span
          v-for="(data, indexs) in item.video_tag.slice(0, 2)"
          :key="indexs"
          >{{ data }}</span
        >
      </div>
      <div class="states">
        <IconFontSymbol
          :icon="'iconstudy'"
          class="left-container-svg"
          :style="{ 'font-size': '14px' }"
        />
        {{ activeStyle ? item && item.play_times : item && item.click }}人已学
      </div>
    </div>
  </div>
</template>
<script>
import IconFontSymbol from "@/components/IconFontSymbol";
export default {
  name: "VideoListComponent",
  props: {
    activeStyle: {
      type: Boolean,
      default: true,
    },
    dataList: {
      type: Array,
      default: [],
    },
    toDetail: {
      type: Function,
    },
    toDetailOther: {
      type: Function,
    },
  },
  components: {
    IconFontSymbol,
  },
};
</script>
<style lang="scss" scoped>
.list-container {
  display: flex;
  margin-bottom: 10px;
  font-size: 0;
  .left-contents {
    width: calc(50vw - 10px);
    position: relative;
    .bottom-right-times {
      position: absolute;
      right: 0px;
      bottom: 0px;
      padding: 5px 10px;
      text-align: center;
      background: rgba(0, 0, 0, 0.15);
      border-radius: 6px 0px 6px 0px;
      color: #ffffff;
      font-size: 0.12rem;
    }
    img {
      width: calc(50vw - 10px);
      border-radius: 6px;
    }
  }
  .right-contents {
    width: calc(50vw - 30px);
    // width: calc(50vw - 20px);
    padding-left: 10px;
    .title {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 550;
      color: #333333;
      font-size: 0.15rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .tips {
      margin-top: 10px;
      span {
        padding: 2px 4px;
        font-size: 0.12rem;
        border-radius: 4px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        background: #fff8f2;
        color: #ff9d00;
        margin-right: 6px;
      }
      span:last-child {
        margin-right: 0px;
      }
    }
    .states {
      margin-top: 10px;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      .left-container-svg {
        margin-right: 6px;
        font-size: 0.14rem;
      }
    }
  }
}
</style>
