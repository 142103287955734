export default {
  // 云课堂
  clicks: "/api/v1/college/clicks/", // 统计点击次数
  video_list: "/api/v1/college/video_list/", // 视频
  subjects: "/api/v1/college/subjects/", // 课程

  // 课程详情页
  col_status: "/api/v1/college/col_status/", //
  collections: "/api/v1/college/collections/", //
  history: "/api/v1/college/history/", //
  times: "/api/v1/college/times/", //
  progress: "/api/v1/college/sub_detail/progress", //
  sub_detail: "/api/v1/college/sub_detail/", //
  video_info: "/api/v1/college/video_info/", //
};
