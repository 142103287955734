<template>
  <div
    :key="item.id"
    class="list-details"
    v-for="(item, index) in videoList"
    @click="reloadVideo(item.video_id, false, index)"
  >
    <div
      class="left-title"
      :style="{ color: item.active ? '#1D6AF2' : '#333333' }"
    >
      <div>{{ item.video_name }}</div>
      <div>时长 {{ item.video_time }}</div>
    </div>
    <div
      class="right-tip"
      :style="{
        color: state.courseStatus[item.study_state].color,
      }"
    >
      <IconFontSymbol
        style="font-size: 18px"
        :icon="state.courseStatus[item.study_state].icon"
      />
      <span>{{ item.study_state }}</span>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import IconFontSymbol from "@/components/IconFontSymbol";
export default {
  name: "SubjectListComponent",
  props: {
    videoList: {
      type: Array,
      default: [],
    },
    reloadVideo: {
      type: Function,
      default: function () {},
    },
  },
  components: {
    IconFontSymbol,
  },
  setup() {
    const state = reactive({
      courseStatus: {
        未学习: {
          color: "#999999",
          icon: "iconnot_learning",
        },
        学习中: {
          color: "#FF8737",
          icon: "iconlearning",
        },
        已学习: {
          color: "#53A9FF",
          icon: "iconfulfil",
        },
      },
    });
    return {
      state,
    };
  },
};
</script>
<style lang="scss" scoped>
.list-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 0;
  .left-title {
    color: #333333;
    div:nth-child(1) {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 0.17rem;
    }
    div:nth-child(2) {
      font-weight: 500;
      font-size: 0.12rem;
    }
  }
  .right-tip {
    color: #999999;
    text-align: center;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      position: relative;
      top: -2px;
      font-size: 0.14rem;
    }
  }
}
</style>
